@media (min-width: 576px) {
    .card-columns {
        -webkit-column-count: 1 !important;
        column-count: 1 !important;
        -webkit-column-gap: 1.25rem !important;
        -moz-column-gap: 1.25rem !important;
        grid-column-gap: 1.25rem !important;
        column-gap: 1.25rem !important;
        orphans: 1 !important;
        widows: 1 !important;
    }
}

@media (min-width: 1000px) {
    .card-columns {
        -webkit-column-count: 2 !important;
        column-count: 2 !important;
        -webkit-column-gap: 1.25rem !important;
        -moz-column-gap: 1.25rem !important;
        grid-column-gap: 1.25rem !important;
        column-gap: 1.25rem !important;
        orphans: 1 !important;
        widows: 1 !important;
    }
}

@media print {
    .card-columns {
        display: block !important;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
        -webkit-column-count: 1 !important;
        column-count: 1 !important;
        -webkit-column-gap: 1.25rem !important;
        -moz-column-gap: 1.25rem !important;
        grid-column-gap: 1.25rem !important;
        column-gap: 1.25rem !important;
        orphans: 1 !important;
        widows: 1 !important;
    }

    .schedule-table-btn {
        display: block;
    }

    .schedule-table-btn {
        display: block;
    }
}

th {
    width: 45%;
}

.card-header {
    font-size: large !important;
}
