/* Provide sufficient contrast against white background */
body {
    background-color: #f9f9f9;
}

/* Fix flickering tooltips */
.tooltip {
    pointer-events: none;
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff !important;
    background-color: #1b6ec2 !important;
    border-color: #1861ac !important;
}

.text-primary {
    color: #1b6ec2 !important;
}

.btn-outline-primary {
    color: #1b6ec2;
    border-color: #1861ac;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

h1 {
    color: #24B9B6;
    padding-top: .5em;
}

a.disabled-cursor {
  cursor: default;
}

a.disabled-cursor:hover {
  text-decoration: none;  
}

.react-confirm-alert-overlay{
    background:rgba(255,255,255,0.5)
}