.side-bar-react-icons {
    vertical-align: middle;
    font-size: 2em;
}

nav a {
    color: #343a40; /* Bootstrap Dark */
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
}

    nav a.active {
        color: #1b6ec2; /* Bootstrap Primary */
    }

#sidemenu nav a.active {
    border-left: 3px solid #1b6ec2;
}
