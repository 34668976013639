.rdw-editor-main {
  padding: 20px;
}

.rdw-option-wrapper,
.rdw-dropdown-wrapper,
.rdw-link-modal-btn,
.rdw-image-modal-btn {
  transition: color .1s ease-in-out, background-color .1s ease-in-out, border-color .1s ease-in-out, box-shadow .1s ease-in-out;
  border-radius: 0.25rem;
  border: none;
  height: 30px;
}

.rdw-option-wrapper {
  min-width: 30px;
  opacity: 0.6;
}

.rdw-colorpicker-option {
  opacity: 1;
}

.rdw-dropdown-wrapper {
  min-width: 50px;
}

.rdw-link-modal-input,
.rdw-image-modal-url-input {
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  height: calc(1.5em + 0.75rem + 2px);
}

.rdw-option-wrapper.rdw-colorpicker-option {
  height: 22px;
  min-width: 22px;
}

.rdw-link-modal,
.rdw-colorpicker-modal,
.rdw-emoji-modal {
  height: 300px;
  width: 230px;
}

.rdw-option-wrapper:hover,
.rdw-dropdown-wrapper:hover,
.rdw-link-modal-btn:hover,
.rdw-image-modal-btn:hover {
  box-shadow: none;
  background-color: #f5f5f5;
}

.rdw-option-wrapper:active,
.rdw-dropdown-wrapper:active,
.rdw-option-active:active,
.rdw-link-modal-btn:active,
.rdw-image-modal-btn:active,
.rdw-option-active {
  background-color: #e9e9e9;
  box-shadow: none;
  opacity: 1;
}