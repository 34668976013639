#sidemenu {
    width: 150px;
}

#main-content {
    min-width: 200px;
}

#profile-page .form-group,
#create-user-page .form-group,
#edit-user-page .form-group,
#create-firmware-page .form-group,
#edit-firmware-page .form-group {
    max-width: 600px;
}
