a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html { font-size: 14px; }

@media (min-width: 768px) {
    html { font-size: 16px; }
}

.header-react-icons {
    vertical-align: middle;
    font-size: 1.5em;
}

.nav-brand-logo {
}

.nav-brand-logo:hover {
    opacity: 0.7;
}

.nav-brand-text {
    float: right;
    line-height: 40px;
    padding-left: 15px;
}
